import {req} from "vuelidate/src/validators/common";

const required = 'V.REQUIRED'

const email = 'V.EMAIL'
const phone = 'V.PHONE'
const numeric = 'V.NUMERIC'
const minValue = (value) => {
    return {
        text: 'V.MIN_VALUE',
        value
    }
}
const maxValue = (value) => {
    return {
        text: 'V.MAX_VALUE',
        value
    }
}
const greaterThan = (value) => {
    return {
        text: 'V.GREATER_THAN',
        value
    }
}
const valueBetween = (min, max) => {
    return {
        text: 'V.VALUE_BETWEEN',
        value: {
            min,
            max
        }
    }
}
const minLength = (length) => {
    return {
        text: 'V.MIN_LENGTH',
        value: length
    }
}
const maxLength = (length) => {
    return {
        text: 'V.MAX_LENGTH',
        value: length
    }
}
const lengthBetween = (min, max) => {
    return {
        text: 'V.LENGTH_BETWEEN',
        value: {
            min,
            max
        }
    }
}
const requiredAndMinLength = (value) => {
    return {
        text: 'V.FIELD_REQUIRED_AND_MIN_LENGTH',
        value
    }
}

const allowed = (value) => ({text: 'V.ALLOWED_CHARS', value})


export const ACCOMMODATION_PRICING_RATE_PLAN_NAME_VALIDATION_FAIL = {code: 29015, message: required}
export const ACCOMMODATION_PRICING_RATE_PLAN_DISTRIBUTION_VALIDATION_FAIL = {code: 29016, message: required}
export const ACCOMMODATION_PRICING_RATE_PLAN_TYPE_VALIDATION_FAIL = {code: 29033, message: required}
export const ACCOMMODATION_PRICING_PARENT_RATE_PLAN_VALIDATION_FAIL = {code: 29034, message: required}
export const ACCOMMODATION_PRICING_RATE_PLAN_CHARGE_PROFILE_VALIDATION_FAIL = {code: 29017, message: required}
export const ACCOMMODATION_PRICING_RATE_PLAN_CANCELLATION_POLICY_VALIDATION_FAIL = {code: 29036, message: required}
export const ACCOMMODATION_PRICING_RATE_PLAN_LINKED_VALUE_VALIDATION_FAIL = {code: 29038, message: numeric}
export const ACCOMMODATION_PRICING_RATE_PLAN_LINKED_TYPE_VALIDATION_FAIL = {code: 29037, message: 'TYPE_MANDATORY'}
export const ACCOMMODATION_PRICING_RATE_PLAN_LINKED_VALUE_PERC_VALIDATION_FAIL = {code: 29039, message: 'V.INVALID_PERCENTAGE'}
export const ACCOMMODATION_PRICING_RATE_PLAN_LOS_VALIDATION_FAILED = {code:29040, message: required}
export const ACCOMMODATION_PRICING_RATE_PLAN_COPY_RESTRICTIONS_SOURCE_UNIT_REQUIRED = {code: 29066, message: required}
export const ACCOMMODATION_PRICING_RATE_PLAN_COPY_RESTRICTIONS_SOURCE_RATE_PLAN_REQUIRED = {code: 29067, message: required}
export const ACCOMMODATION_PRICING_RATE_PLAN_COPY_RESTRICTIONS_RESTRICTION_LIST_REQUIRED = {code: 29068, message: 'V.SELECT_RESTRICTIONS_TO_COPY'}
export const ACCOMMODATION_PRICING_RATE_PLAN_COPY_RESTRICTIONS_DESTINATION_UNIT_LIST_REQUIRED = {code: 29069, message: required}
export const ACCOMMODATION_PRICING_RATE_PLAN_COPY_RESTRICTIONS_DESTINATION_DISTRIBUTION_LIST_REQUIRED = {code: 29070, message: required}
export const  ACCOMMODATION_PRICING_RATE_PLAN_COMMUNICATION_TYPE =  {code: 29082, message: required}
export const  ACCOMMODATION_PRICING_RATE_PLAN_COMMUNICATION_TYPE_OUTBOUND_EXIST =  {code: 29083}

// INVOICE TYPE, VAT, ARTICLE
export const INVOICE_TYPE_NAME_REQUIRED = {code: 12051, message: required}
export const SYSTEM_INVOICE_TYPE_REQUIRED = {code: 12053, message: required}
export const INVOICE_TYPE_INVOICE_CITY_SOURCE_REQUIRED = {code: 12056, message: required}
export const INVOICE_TYPE_INVOICE_NUMBER_SOURCE_REQUIRED = {code: 12057, message: required}
export const VAT_LABEL_VALIDATION_FAILED = {code: 12045, message: required}
export const VAT_AMOUNT_VALIDATION_FAILED = {code: 12046, message: [required, valueBetween(0, 99.99)]}
export const INVOICE_TYPE_INVOICE_NUMBER_TEMPLATE = {message: required, code: 12074}

// INVOICE (ITEM) CREATION SETUP
export const INVOICE_CREATION_SETUP_INVOICE_TYPE_REQUIRED = {code: 12062, message: required}
export const INVOICE_CREATION_SETUP_CREATION_LOCATION_REQUIRED = {code: 12063, message: required}

// CURRENCY EXCHANGE FIXED
export const FIN_CE_FIXED_SOURCE_CURRENCY_REQUIRED = {code: 12065, message: required}
export const FIN_CE_FIXED_DESTINATION_CURRENCY_REQUIRED = {code: 12066, message: required}
export const FIN_CE_FIXED_VALUE_NOT_VALID = {code: 12067, message: [required, minValue(0)]}

//event template error codes
export const ET_LABEL_REQUIRED = {code: 52002, message: required}
export const ET_EVENT_REQUIRED = {code: 52003, message: required}
export const ET_RECIPIENT_TYPE_REQUIRED = {code: 52004, message: required}
export const ET_SUBJECT_REQUIRED = {code: 52005, message: required}
export const ET_CONTENT_REQUIRED = {code: 52006, message: required}
export const ET_CONTACT_CHANNEL_REQUIRED = {code: 52009, message: required}

//user error codes
export const USER_FIRST_NAME_REQUIRED = {code: 1019, message: required}
export const USER_LAST_NAME_REQUIRED = {code: 1020, message: required}
export const USER_PREFERRED_LANGUAGE_REQUIRED = {code: 1026, message: required}
export const USER_PHONE_VALIDATION_FAILED = {code: 1017, message: phone}
export const USER_USERNAME_REQUIRED = {code: 1034, message: required}
export const USER_USERNAME_EMAIL_VALIDATION_FAILED = {code: 1035, message: email}
export const USER_USERNAME_ALREADY_EXISTS = {code: 1036, message: 'EMAIL_EXIST'}
export const USER_EMAIL_REQUIRED = {code: 1027, message: required}
export const USER_OLD_PASSWORD_REQUIRED = {code: 1037, message: required}
export const USER_OLD_PASSWORD_VALIDATION_FAILED = {code: 1038}

//tag
export const NO_TAG_ITEM_SELECTED = {code:35003, message: required}
export const TAG_ALREADY_EXIST = {code:35005}
export const TAG_LABEL_VALIDATION_FAIL = {code:35006 , message: required}
export const TAG_COMPANY_VALIDATION_FAIL = {code:35007}
export const TAG_TYPE_VALIDATION_FAIL = {code:35008}
export const TAG_ID_VALIDATION_FAIL = {code:35009, message:required}

// register user
export const USER_VALIDATION_FAILED_EMAIL = {
    code: 1007, message: email
}
export const USER_VALIDATION_FAILED_PASSWORD_MIN_LENGTH = {
    code: 1010, message: minLength(10)

}
export const USER_VALIDATION_FAILED_PASSWORD_NOT_SAME = {
    code: 1023, message: 'V.PASSWORD_NOT_SAME'
}
export const USER_VALIDATION_FAILED_REPEAT_PASSWORD_REQUIRED = {
    code: 1033, message: required
}

export const USER_VALIDATION_FAILED_PASSWORD_REUSED = {
    code: 1016
}

export const USER_VALIDATION_FAILED_PHONE = {
    code: 1017, message: phone
}
export const USER_VALIDATION_FAILED_EMAIL_ALREADY_EXIST = {
    code: 1008, message: 'EMAIL_EXIST'
}

export const USER_VALIDATION_FAILED_PASSWORD_MUST_CONTAIN_NUMBERS_AND_LETTERS = { code: 1046 }
export const USER_VALIDATION_FAILED_PASSWORD_MUST_CONTAIN_NUMBER_UPPERCASE_AND_LOWERCASE_LETTER = { code: 1047 }

//guest
export const GUEST_ACCESS_DATA_USERNAME_VALIDATION_FAILED = {code: 7029, message: required}
export const GUEST_ACCESS_DATA_PASSWORD_VALIDATION_FAILED = {code: 7030, message: required}

export const GUEST_ACCESS_DATA_FACILITY_CODE_VALIDATION_FAILED = {code: 7031, message: required}
export const GUEST_ACCESS_DATA_GUEST_CHECKIN_NUMBER_VALIDATION_FAILED = {code: 7039, message: required}


// property

export const PROPERTY_DATA_NAME_VALIDATION_FAILED = {code: 4003, message: required}
export const PROPERTY_DATA_ADDRESS_VALIDATION_FAILED = {code: 4009, message: required}
export const PROPERTY_DATA_ADDRESS_NO_VALIDATON_FAILED = {code: 4010, message: required}
export const PROPERTY_DATA_LATITUDE_VALIDATION_FAILED = {code: 4011, message: required}
export const unit_data_invalid_longitude_validation_failed = {code: 5128, message: 'V.INVALID_LONGITUDE'}
export const unit_data_invalid_latitude_validation_failed = {code: 5129, message: 'V.INVALID_LATITUDE'}
export const PROPERTY_DATA_LONGITUDE_VALIDATION_FAILED = {code: 4012, message: required}
export const PROPERTY_DATA_POSTAL_NUMBER_VALIDATION_FAILED = {code: 4014, message: required}
export const PROPERTY_DATA_CITY_VALIDATION_FAILED = {code: 4013, message: required}
export const PROPERTY_DATA_COUNTY_VALIDATION_FAILED = {code: 4017, message: required}
export const PROPERTY_DATA_COUNTRY_VALIDATION_FAILED = {code: 4018, message: required}
export const PROPERTY_DATA_CATEGORIZATION_VALIDATION_FAILED = {code: 4005, message: required}
export const PROPERTY_DATA_UNIT_NUMBER_VALIDATION_FAILED = {code: 4004, message: required}
export const PROPERTY_DATA_UNIT_NUMBER_VALIDATION_FAILED_SERVER_MESSAGE = {code: 4004}
export const PROPERTY_DATA_PROPERTY_TYPE_VALIDATION_FAILED = {code: 4007, message: required}
export const PROPERTY_DATA_INVALID_LONGITUDE_VALIDATION_FAILED = {code: 4158, message: 'V.INVALID_LONGITUDE'}
export const PROPERTY_DATA_INVALID_LATITUDE_VALIDATION_FAILED = {code: 4157, message: 'V.INVALID_LATITUDE'}
export const PROPERTY_DATA_UNIT_NUMBER_NUMERIC_VALIDATION_FAILED = {code: 4159, message: numeric}

export const PROPERTY_DATA_UNIT_TYPE_VALIDATION_FAILED = {code:4063, message:required}
export const PROPERTY_DATA_NUMBER_BEDROOMS_VALIDATION_FAILED = {code:4067, message: numeric}
export const PROPERTY_DATA_NUMBER_BEDROOMS_MANDATORY_FIELD = {code:4165, message:required}
export const UNIT_DATA_OWNER_IS_MANDATORY_VALIDATION_FAILED = {code:5123, message:required}
export const PROPERTY_DATA_SIZE_VALIDATION_FAILED = {code:4065, message: numeric}
export const PROPERTY_DATA_SIZE_MANDATORY_FIELD= {code:4163, message:required}

export const PROPERTY_DATA_FLOOR_VALIDATION_FAILED = {code:4064, message: numeric}
export const PROPERTY_DATA_FLOOR_MANDATORY_FIELD = {code:4164, message:required}

export const PROPERTY_DATA_MAX_GUESTS_VALIDATION_FAILED = {code:4058, message:minValue(1)}
export const PROPERTY_DATA_MAX_GUESTS_MANDATORY_FIELD = {code:4161, message:required}
export const PROPERTY_DATA_MAX_ADULTS_VALIDATION_FAILED = {code:4059, message:minValue(1)}
export const PROPERTY_DATA_MAX_ADULTS_MANDATORY_FIELD = {code:4162, message:required}
export const PROPERTY_DATA_MAX_CHILDREN_VALIDATION_FAILED = {code:4060, message:'V.INVALID_NUMBER'}
export const PROPERTY_DATA_MAX_BABIES_VALIDATION_FAILED = {code:4061, message:'V.INVALID_NUMBER'}
export const PROPERTY_DATA_MAX_BABIES_MANDATORY_FIELD = {code:4166, message:required}
export const PROPERTY_DATA_MAX_CHILDREN_MANDATORY_FIELD = {code:4167, message:required}
export const MAPPING_PROPERTY_EID = {code:32005, message:required}

export const CM_PROPERTY_STATUS_NOT_EXIST = {code:34024, message:required}

export const PROPERTY_IMPORT_NOT_UNIQUE_EXTERNAL_ID = {code: 4184}
export const PROPERTY_IMPORT_EXISTING_PROPERTY_STATUS_VALIDATION_FAILED = {code: 4185}
export const PROPERTY_IMPORT_NO_UNITS = {code: 4186}
export const PROPERTY_IMPORT_DISTRIBUTION_NOT_SET = {code: 4187, message: required}
export const PROPERTY_IMPORT_BOOKING_EXTERNAL_ID = {code: 4189}
export const PROPERTY_IMPORT_AIRBNB_HOST_ID = {code: 4190}
export const PROPERTY_IMPORT_SELECTED_UNITS_EMPTY = {code: 4191, message: required}
export const PROPERTY_IMPORT_UNIT_NOT_UNIQUE_EXTERNAL_ID = {code: 4192}
export const PROPERTY_IMPORT_EXISTING_UNIT = {code: 4193}
export const PROPERTY_IMPORT_IMPORT_MODE_EMPTY = {code: 4194, message: required}
export const PROPERTY_IMPORT_EXISTING_PROPERTY_EMPTY = {code: 4195, message: required}


export const UNIT_DATA_NAME_VALIDATION_FAILED = {code: 5102, message: required}
export const UNIT_DATA_LATITUDE_VALIDATION_FAILED = {code:5110, message:required}
export const UNIT_DATA_LONGITUDE_VALIDATION_FAILED = {code:5111, message:required}
export const UNIT_DATA_ADDRESS_VALIDATION_FAILED = {code:5107, message:required}
export const UNIT_DATA_ADDRESS_NO_VALIDATION_FAILED = {code:5125, message:required}
export const UNIT_DATA_NUMBER_OF_UNIT_VALIDATION_FAILED = {code:5127, message: numeric}
export const UNIT_DATA_NUMBER_OF_UNIT_MANDATORY_FIELD = {code:5303, message:required}
export const UNIT_DATA_GROUP_NAME_VALIDATION_FAILED = {code:5126, message:required}
export const UNIT_DATA_INVALID_LATITUDE_VALIDATION_FAILED = {code:5129, message: 'V.INVALID_LATITUDE'}
export const UNIT_DATA_INVALID_LONGITUDE_VALIDATION_FAILED = {code:5128, message: 'V.INVALID_LONGITUDE'}
export const INVENTORY_DEFAULT_RESTRICTION_MIN_STAY_VALIDATION_FAILED = {code:59003, message:required}



export const CANCELLATION_POLICY_NAME_MIN_LENGTH = {code: 4160, message: minLength(3)}

// owner
export const OWNER_VALIDATION_NAME_VALIDATION_FAIL = {code: 23010, message: required}
export const OWNER_VALIDATION_CONTACT_EMAIL_VALIDATION_FAIL = {code: 23032, message: email}
export const OWNER_VALIDATION_PERSON_TYPE_VALIDATION_FAIL = {code: 23017, message: required}
export const OWNER_VALIDATION_CONTACT_PHONE_VALIDATION_FAIL = {code: 23031, message: phone}


//guest

export const GUEST_POST_FIRST_NAME_VALIDATION_FAILED = {code:7010, message:required}
export const GUEST_POST_LAST_NAME_VALIDATION_FAILED = {code:7011, message:required}
export const GUEST_POST_RESERVATION_VALIDATION_FAILED = {code:7027, message:required}
export const GUEST_POST_GENDER_VALIDATION_FAILED = {code:7012, message:required}
export const GUEST_POST_EMAIL_VALIDATION_FAILED = {code:7013, message:email}
export const GUEST_POST_CHECK_IN_DATE_VALIDATION_FAILED = {code:7014, message:required}
export const GUEST_POST_CHECK_OUT_DATE_VALIDATION_FAILED = {code:7015, message:required}
export const GUEST_POST_CHECK_IN_TIME_VALIDATION_FAILED = {code:7016, message:required}
export const GUEST_POST_CHECK_OUT_TIME_VALIDATION_FAILED = {code:7017, message:required}
export const GUEST_POST_DATE_OF_BIRTH_VALIDATION_FAILED = {code:7018, message:required}
export const GUEST_POST_COUNTRY_OF_BIRTH_VALIDATION_FAILED = {code:7019, message:required}
export const GUEST_POST_CITIZENSHIP_VALIDATION_FAILED = {code:7020, message:required}
export const GUEST_POST_COUNTRY_OF_RESIDENCE_VALIDATION_FAILED = {code:7021, message:required}
export const GUEST_POST_CITY_OF_RESIDENCE_VALIDATION_FAILED = {code:7022, message:required}
export const GUEST_POST_DOCUMENT_TYPE_VALIDATION_FAILED = {code:7023, message:required}
export const GUEST_POST_DOCUMENT_NUMBER_VALIDATION_FAILED = {code:7024, message:required}
export const GUEST_POST_ARRIVAL_ORGANISATION_VALIDATION_FAILED = {code:7025, message:required}
export const GUEST_POST_PAYMENT_CATEGORY_VALIDATION_FAILED = {code:7026, message:required}
export const GUEST_POST_PHONE_VALIDATION_FAILED = {code:7032, message:phone}



// GuestConnectionErrorCodes - AJPES (51200 - 51299)
export const GC_CONN_AJPES_FIRSTNAME_NOT_VALID = {code:51200, message:  lengthBetween(2, 142)};
export const GC_CONN_AJPES_GENDER_NOT_VALID = {code:51202,message:required}; // Gender is mandatory, valid options are M and F
export const GC_CONN_AJPES_DATE_OF_BIRTH_NOT_VALID = {code:51203, message:required}; // Date of birth is not valid
export const GC_CONN_AJPES_COUNTRY_OF_RESIDENCE_NOT_VALID = {code:51204, message:required}
export const GC_CONN_AJPES_DOCUMENT_TYPE_NOT_VALID = {code:51205, message:required};
export const GC_CONN_AJPES_DOCUMENT_NUMBER_NOT_VALID = {code:51206, message:required}
export const GC_CONN_AJPES_STAY_FROM_NOT_VALID = {code:51207, message:required}
export const GC_CONN_AJPES_TIME_STAY_FROM_NOT_VALID = {code:51208,message:required}
export const GC_CONN_AJPES_PAYMENT_CATEGORY_NOT_VALID = {code:51210, message:required}
export const GC_CONN_AJPES_CITY_TAX_NOT_DEFINED = 51212;




// reservation
export const AR_UNIT = {code: 3024, message: required};
export const AR_GUEST_FIRST_NAME = {code: 3025, message: required};
export const AR_GUEST_LAST_NAME = {code: 3026, message: required};
export const AR_GUEST_COUNTRY = {code: 3027, message: required};
export const AR_GUEST_EMAIL = {code: 3028, message: email};
export const AR_GUEST_PHONE = {code: 3029, message: phone};
export const AR_NUM_ADULTS = {code: 3031, message: required};
export const AR_NUM_CHILDREN = {code: 3032, message: required};
export const AR_NUM_BABIES = {code: 3033, message: required};
export const AR_STATUS = {code: 3034, message: required};
export const AR_CHANNEL_CURRENCY = {code: 3035, message: required};
export const AR_CHANNEL_TOTAL = {code: 3036, message: required};
export const AR_CREATE_FAILED = {code: 3037, message: required};
export const AR_CHANNEL = {code: 3038, message: required};
export const AR_CHECK_IN_DATE = {code: 3040};
export const AR_CHECK_OUT_DATE = {code: 3041};
export const AR_COMPANY = {code: 3042, message: required};
export const AR_CHARGE_PROFILE = {code: 3043, message: required};
export const AR_PERIOD = {code: 3044, message: required};
export const AR_CURRENCY_VALUE = {code: 3048, message: required};
export const AR_VALIDATE_UNIT_UPDATE = {code: 3074, message: 'VALIDATION_FAILED'};
export const AR_CM_PRODUCT_STATUS = {code: 3076, message: required};
export const AR_RATE_PLAN = {code: 3045, message: required}

// reservation scheduler
export const AR_SCHEDULER_NOTIFICATION_EVENT_TEMPLATE = {code: 3069, message: required};

// cc
export const CC_EMPTY_NAME = {code: 55000, message: required};
export const CC_EMPTY_NUMBER = {code: 55001, message: required};
export const CC_EMPTY_EXPIRATION_DATE = {code: 55002, message: required};
export const CC_EMPTY_CVV = {code: 55003, message: required};
export const CC_EMPTY_TYPE = {code: 55004, message: required};

// company
export const COM_NAME_VALIDATION_FAIL = {code: 40006, message: required};
export const COM_COUNTRY_VALIDATION_FAIL = {code: 40007, message: required};
export const COM_CITY_VALIDATION_FAIL = {code: 40008, message: required};
export const COM_ADDRESS_VALIDATION_FAIL = {code: 40009, message: required};
export const COM_TYPE_VALIDATION_FAIL = {code: 40011, message: required};
export const COM_VAT_ID_VALIDATION_FAIL = {code: 40012, message: required};
export const COM_REGISTRATION_NUMBER_VALIDATION_FAIL = {code: 40013, message: required};
export const COM_AGENCY_ID_CODE_VALIDATION_FAIL = {code: 40014, message: required};
export const COM_LEGAL_REPRESENTATIVE_VALIDATION_FAIL = {code: 40015, message: required};
export const COM_BILLING_ACCOUNT_VALIDATION_FAIL = {code: 40016, message: required};
export const COM_PAYOUT_ACCOUNT_VALIDATION_FAIL = {code: 40054, message: required};
export const COM_IBAN_VALIDATION_FAIL = {code: 40017, message: required};
export const COM_CURRENCY_VALIDATION_FAIL = {code: 40018, message: required};
export const COM_VAT_PERCENTAGE_VALIDATION_FAIL = {code: 40019, message: required};
export const COM_VAT_SYSTEM_VALIDATION_FAIL = {code: 40026, message: required};
export const COM_BIC_VALIDATION_FAIL = {code: 40045, message: required};

export const COM_MAIL_SERVER_USERNAME_VALIDATION_FAILED = {code: 40046, message: required};
export const COM_MAIL_SERVER_PASSWORD_VALIDATION_FAILED = {code: 40047, message: required};
export const COM_MAIL_SERVER_HOST_VALIDATION_FAILED = {code: 40048, message: required};
export const COM_MAIL_SERVER_PORT_VALIDATION_FAILED = {code: 40049, message: required};
export const COM_MAIL_SERVER_DRIVER_VALIDATION_FAILED = {code: 40050, message: required};
export const COM_MAIL_SERVER_ENCRYPTION_VALIDATION_FAILED = {code: 40051, message: required};


export const COM_CITY_ID_REQUIRED = {code: 40037, message: required}
export const COM_COUNTRY_ID_REQUIRED = {code: 40038, message: required}
export const COM_MAIN_CURRENCY_ID_REQUIRED = {code: 40039, message: required}
export const COM_EMPTY_WORKING_CURRENCY =  {code: 40002, message: required}
export const COM_EXCHANGE_RATE_REQUIRED = {code: 40040, message: required}
export const COM_EXCHANGE_RATE_MUST_BE_NUMERIC = {code: 40041, message: numeric}
export const COM_EXCHANGE_RATE_MUST_BE_GREATER_THAN_ZERO = {code: 40042, message: greaterThan(0)}

// invoice
export const IN_DESCRIPTION = {code: 12007, message: required}
export const IN_DELIVERY_DATE = {code: 54023, message: required}
export const IN_DUE_DATE = {code: 54024, message: required}
export const IN_CLIENT = {code: 12003, message: required}
export const IN_CLIENT_NAME = {code: 12072, message: required}
export const IN_CLIENT_EMAIL = {code: 12073, message: required}
export const IN_CLIENT_OIB = {code: 12026, message: required}
export const IN_CLIENT_VAT = {code: 12033}
export const IN_CLIENT_ADDRESS = {code: 12028, message: required}
export const IN_CLIENT_CITY = {code: 12029, message: required}
export const IN_CLIENT_TYPE = {code: 12075, message: required}
export const IN_CLIENT_IBAN = {code: 12076, message: required}


// sales periods
export const SALES_PERIOD_NAME_REQUIRED = {code: 29035, message: required}

// subscription
export const SU_FEE_PERCENT_IS_MANDATORY = {code: 56000, message: required}
export const SU_FEE_PERCENT_MUST_BE_NUMERIC = {code: 56001, message: numeric}
export const SU_FEE_PERCENT_MUST_BE_GREATER_THAN_ZERO = {code: 56002}
export const SU_FEE_PERCENT_CANNOT_BE_GREATER_THAN_HUNDRED = {code: 56022}
export const SU_CANCELLATION_FEE_AMOUNT_MUST_BE_NUMERIC = {code: 56011, message: numeric}
export const SU_CANCELLATION_FEE_AMOUNT_CANNOT_BE_LESS_THAN_ZERO = {code: 56012}
export const SU_MAX_AMOUNT_MUST_BE_NUMERIC = {code: 56013, message: numeric}
export const SU_MAX_AMOUNT_CANNOT_BE_LESS_THAN_ZERO = {code: 56014}
export const SU_EXPIRATION_DATE_NOT_VALID = {code: 56021, message: required}

export const CM_PRICING_MODE_VALIDATION_FAIL = {code:34023, message:required}
export const CM_PAYMENT_MODE_VALIDATION_FAIL = {code:34026, message:required}
export const CM_CONNECTION_TYPE_VALIDATION_FAIL = {code:34025, message:required}
export const CM_MAPPING_UNIT_EID_VALIDATION_FAIL = {code:32015, message:required}
export const CM_ADVERTISER_ID_REQUIRED = {code: 34034, message: required}
export const CM_ADVERTISER_ID_NOT_VALID = {code: 34035}
export const CM_MERCHANT_NAME_REQUIRED = {code: 34036, message: required}
export const CM_MERCHANT_NAME_MAX_LENGTH_VALIDATION_FAILED = {code: 34037}
export const CM_RENTAL_AGREEMENT_REQUIRED = {code: 34038, message: required}
export const CM_LEGAL_ENTITY_LEGAL_CONTACT_EMAIL_VALIDATION_FAILED = {code: 34047}
export const CM_LEGAL_ENTITY_LEGAL_CONTACT_NAME_VALIDATION_FAILED = {code: 34048, message:required}
export const CM_LEGAL_ENTITY_PREFERRED_LANGUAGE_VALIDATION_FAILED = {code: 34050, message:required}
export const CM_LEGAL_ENTITY_ID_VALIDATION_FAILED = {code: 34056, message: numeric}


//cash register

export const CR_CASH_REGISTER_EMPTY = {code:53020, message:required}
export const CR_NAME_EMPTY = {code:53021, message:required}
export const CR_ADDRESS_EMPTY = {code:53022, message:required}
export const CR_CITY_EMPTY  = {code:53023, message:required}
export const CR_COMPANY_VAT_ID_EMPTY = {code:53010, message:required}
export const CR_INVALID_IBAN = {code:53024, message:required}
export const CR_LANGUAGE_EMPTY = {code:53025, message:required}
export const CR_WORKING_USER_EMPTY = {code:53027, message:required}
export const CR_CERTIFICATE_EMPTY = {code:53028,message:required}
export const CR_CERTIFICATE_PASSWORD_EMPTY = {code:53029, message:required}
export const CR_WORKING_USER_VAT_NUMBER_EMPTY = {code:53030, message:required}
export const CR_IN_VAT_SYSTEM_EMPTY = {code:53040, message:required}

//direct booking
export const DBWE_RATE_PLAN_NAME = {code:63027, message:required};
export const DBWE_RATE_PLAN_LINKED_VALUE = {code:63028, message:required};
export const DBWE_RATE_PLAN_CANCELLATION_POLICY = {code:63029, message:required};
export const DBWE_PAGE_PATH = {code: 66003, message: [required, allowed("a-z, A-Z, 0-9, -, _")]}
export const DBWE_PAGE_TITLE = {code: 66004, message: required}
export const DBWE_PAGE_PATH_ALREADY_EXISTS = {code: 66005}
export const WEBSITE_SUBDOMAIN_ALREADY_EXISTS = {code: 66000}
export const WEBSITE_NAME = {code: 66006}
export const WEBSITE_SUBDOMAIN = {code: 66007}
export const WEBSITE_SUPPORTED_LANGUAGES = {code: 66008}
export const WEBSITE_DEFAULT_LANGUAGE = {code: 66009}
export const WEBSITE_CURRENCY = {code: 66010}
export const WEBSITE_NAME_ALREADY_EXISTS = {code: 66011}
export const WEBSITE_TITLE = {code: 66012}
export const WEBSITE_LOCATION_TOP_LEVEL = {code: 66013}
export const WEBSITE_LATITUDE_VALIDATION = {code: 66036 , message: 'V.INVALID_LATITUDE'}
export const WEBSITE_LONGITUDE_VALIDATION = {code: 66037, message: 'V.INVALID_LONGITUDE'}
export const WE_ACTIVATION_UNIT_LINKED_VALUE_EMPTY = {code: 66030, message: required};
export const WE_ACTIVATION_UNIT_CANCELLATION_POLICY_EMPTY = {code: 66031, message: required};
export const WE_ACTIVATION_UNIT_LINKED_VALUE_NON_REF = {code: 66032, message:required};
export const WE_ACTIVATION_UNIT_CANCELLATION_POLICY_NON_REF = {code: 66033, message:required};
export const WE_ACTIVATION_UNIT_PLAN_NAME_STANDARD = {code: 66034, message:required};
export const WE_ACTIVATION_UNIT_PLAN_NAME_NON_REF = {code: 66035, message:required};
export const WE_ACTIVATION_UNIT_LINKED_VALUE_NOT_NUMBER = {code: 66039, message:numeric};
export const WE_ACTIVATION_UNIT_LINKED_VALUE_NON_REF_NOT_NUMBER = {code: 66040, message: numeric};
export const WEBSITE_FILTERS_ID_NOT_FOUND = {code: 66046, message:required};
export const WEBSITE_FILTERS_PRICE_EMPTY = {code: 66047, message:required};
export const WEBSITE_FILTERS_PRICE_FROM_NOT_FOUND = {code: 66048, message:required};
export const WEBSITE_FILTERS_PRICE_TO_NOT_FOUND = {code: 66049, message:required};
export const WEBSITE_FILTERS_BEDROOMS_EMPTY = {code: 66050, message:required};
export const WEBSITE_FILTERS_BATHROOMS_EMPTY = {code: 66051, message:required};
export const WEBSITE_FILTERS_TYPE_EMPTY = {code: 66052, message:required};
export const WEBSITE_FILTERS_AMENITIES_EMPTY = {code: 66053, message:required};
export const WE_ACTIVATION_UNIT_CHARGE_PROFILE_EMPTY = {code: 66054, message: required};
export const WE_ACTIVATION_UNIT_CHARGE_PROFILE_EMPTY_NON_REF = {code: 66055, message: required};
export const WE_ACTIVATION_UNIT_LINKED_VALUE_PERCENTAGE_VALUE = {code: 66056}
export const WE_ACTIVATION_UNIT_LINKED_VALUE_PERCENTAGE_VALUE_NON_REF = {code: 66057}
export const DB_AMENITY_CATEGORY_LABEL_EMPTY = {code: 63067, message:required};
export const AR_VALIDATE_UNIT_UPDATE_WITH_PERMISSION = {code: 3077};


// payment gateway
export const PG_AMOUNT_LEQUAL_ZERO = {code: 50004, message: greaterThan(0)}
export const PG_SETUP_LOGIN_DATA_CONNECTION_KEY_NOT_SET = {code: 50031, message: required}
export const PG_SETUP_LOGIN_DATA_CONNECTION_TOKEN_NOT_SET = {code: 50032, message: required}
export const PG_SETUP_LOGIN_DATA_API_KEY_NOT_SET = {code: 50033, message: required}
export const PG_SETUP_LOGIN_DATA_PAYMENT_GATEWAY_NOT_SET = {code: 50034, message: required}

//revenue manager
export const BOOKING_WINDOW_SETUP_NAME = {code:65007,message:required}
export const BOOKING_WINDOW_SETUP_TYPE = {code:65008,message:required}
export const FILTER_TITLE = {code:27002,message:required}

// distribution
export const DISTRIBUTION_NAME_REQUIRED = {code: 69000, message: required}
export const DISTRIBUTION_NAME_MIN_LENGTH_VALIDATION_FAILED = {code: 69001}

//inbox
export const TH_MESSAGE_THREAD_CREATE_SPECIAL_OFFER_DATE_FROM_VALIDATION_FAILED = {code:36013,message:required}
export const TH_MESSAGE_THREAD_CREATE_SPECIAL_OFFER_DATE_TO_VALIDATION_FAILED = {code:36014, message:required}
export const TH_MESSAGE_THREAD_CREATE_SPECIAL_OFFER_PRICE_GREATER_THAN_ZERO = {code:36015,message:required}
export const TH_MESSAGE_THREAD_CREATE_SPECIAL_OFFER_UNIT_ID_VALIDATION_FAILED = {code:36011,message:required}
export const TH_MESSAGE_THREAD_CREATE_SPECIAL_OFFER_MAPPING_UNIT_DOES_NOT_EXIST = {code:36012}

//promo codes
export const PROMO_CODE_CATEGORY_ID_VALIDATION_FAILED = {code: 67000, message: required}
export const PROMO_CODE_CODE_VALUE_VALIDATION_FAILED = {code: 67008, message: [required, lengthBetween(4, 255)]}
export const PROMO_CODE_VALUES_REQUIRED_VALIDATION_FAILED = {code: 67012, message: required};
export const PROMO_CODE_VALUES_RANGE_VALIDATION_FAILED = {code: 67011, message: valueBetween(0.01, 99.99)};
export const PROMO_CODE_FIX_VALUES_RANGE_VALIDATION_FAILED = {code: 67010, message: minValue(0.01)};

export const CANCELLATION_PREFERENCE_CUSTOM_TEXT_NOT_SET = {code: 62003, message: required}

//marketplace
export const MARKETPLACE_LOGO_NOT_FOUND = {code: 70002, message: required}
export const MARKETPLACE_DESCRIPTION_NOT_FOUND = {code: 70005, message: required};
export const MARKETPLACE_DESCRIPTION_LENGTH_NOT_VALID = {code: 70006};
export const MARKETPLACE_TOS_NOT_FOUND = {code: 70007, message: required};
export const MARKETPLACE_TERMS_OF_SERVICE_LENGTH_NOT_VALID = {code: 70008};
export const MARKETPLACE_WORKFLOW_NOT_FOUND = {code: 70009, message: required};

//appearance
export const APPEARANCE_VALIDATION_NAME_EMPTY = {code: 72003, message: required};
export const APPEARANCE_VALIDATION_COLOR_EMPTY = {code: 72004, message: required};
export const APPEARANCE_VALIDATION_CATEGORY_EMPTY = {code: 72027, message: required};
export const APPEARANCE_CATEGORY_NAME_EMPTY = {code: 72006, message: required};
export const APPEARANCE_FONT_NAME_NOT_FOUND = {code: 72009, message: required};
export const APPEARANCE_SYSTEM_FONT_NOT_FOUND = {code: 72010, message: required};
export const APPEARANCE_FONT_SOURCE_MISSING = {code: 72014, message: required};
export const APPEARANCE_FONT_FALLBACK_MISSING = {code: 72015, message: required};
export const APPEARANCE_FONT_EXTERNAL_NAME_MISSING = {code: 72016, message: required};
export const APPEARANCE_EXTERNAL_FONT_MISSING = {code: 72017, message: required};
export const APPEARANCE_SYSTEM_FONT_NAME_NOT_FOUND = {code: 72019, message: required};
export const APPEARANCE_SYSTEM_FONT_FONT_NOT_FOUND = {code: 72020, message:required};
export const APPEARANCE_EXTERNAL_FONT_SOURCE_NOT_FOUND = {code: 72021, message:required};
export const APPEARANCE_EXTERNAL_FONT_FALLBACK_NOT_FOUND = {code: 72022, message: required};
export const APPEARANCE_EXTERNAL_FONT_EXTERNAL_NOT_FOUND = {code: 72023, message:required};

//bulk finance details
export const AR_AGENCY_COMMISSION_TYPE_NULL = {code: 3089, message: required};
export const AR_AGENCY_COMMISSION_VALUE_NULL = {code: 3090, message: required};
export const AR_CHANNEL_COMMISSION_TYPE_NULL = {code: 3091, message: required};
export const AR_CHANNEL_COMMISSION_VALUE_NULL = {code: 3092, message: required};
export const AR_CHANNEL_COMMISSION_VAT_TYPE_NULL = {code: 3093, message: required};
export const AR_CHANNEL_COMMISSION_VAT_VALUE_NULL = {code: 3094, message: required};
export const AR_DISTRIBUTION_COMMISSION_TYPE_NULL = {code: 3095, message: required};
export const AR_DISTRIBUTION_COMMISSION_VALUE_NULL = {code: 3096, message: required};
export const AR_DISTRIBUTION_COMMISSION_VAT_TYPE_NULL = {code: 3097, message: required};
export const AR_DISTRIBUTION_COMMISSION_VAT_VALUE_NULL = {code: 3098, message: required};
export const AR_ADDITIONAL_COMMISSION_TYPE_NULL = {code: 3099, message: required};
export const AR_ADDITIONAL_COMMISSION_VALUE_NULL = {code: 3100, message: required};
export const AR_ADDITIONAL_COMMISSION_VAT_TYPE_NULL = {code: 3101, message: required};
export const AR_ADDITIONAL_COMMISSION_VAT_VALUE_NULL = {code: 3102, message: required};
export const AR_BANK_COMMISSION_TYPE_NULL = {code: 3103, message: required};
export const AR_BANK_COMMISSION_VALUE_NULL = {code: 3104, message: required};
export const AR_MARKETING_COMMISSION_TYPE_NULL = {code: 3105, message: required};
export const AR_MARKETING_COMMISSION_VALUE_NULL = {code: 3106, message: required};
export const AR_MARKETING_COMMISSION_VAT_TYPE_NULL = {code: 3107, message: required};
export const AR_MARKETING_COMMISSION_VAT_VALUE_NULL = {code: 3108, message: required};

//payment message
export const PM_MESSAGE_NUMBER_NOT_VALID = {code:18020, message: required}

