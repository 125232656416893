<template>
    <div>
        <b-form @submit.prevent="save" v-if="formData">
            <b-row>
                <app-access-control tag="b-col" class="mb-4" md="6"
                                    @AccessControlEvent="addToAccessControlCounter()"
                                    @updated="ratePlanOptionAccessControlUpdated"
                                    :access_control_context="{function: C_RESERVATION_ACCOMMODATION_FINANCE_RATE_PLAN_E, key: access_control_fetch_key}">
                    <label>{{ $t("RATE_PLAN") }}</label>
                    <app-select v-model="formData.rate_plan"
                                :options="ratePlanList"
                                :search-empty-item="false">
                    </app-select>
                </app-access-control>
                <b-col class="mb-4" md="6"
                       v-if="checkPermission(C_RESERVATION_ACCOMMODATION_FINANCE_FETCH_FINANCIAL_VALUES)">
                    <app-button :class="ratePlanOptionVisible ? 'pull-right' : ''"
                                :style="ratePlanOptionVisible ? 'margin-top: 25px;' : ''"
                                :loading="fetchingFinancialValues"
                                @click="fetchFinanceValues">
                        {{ $t('FETCH_FINANCIAL_VALUES') }}
                    </app-button>
                </b-col>
            </b-row>
            <b-row v-if="checkPermission(C_RESERVATION_ACCOMMODATION_FINANCE_PAYMENT_STATUS_E)">
                <b-col md="6" class="mb-4">
                    <label>{{ $t("PAYMENT_STATUS") }}</label>
                    <app-select v-model="formData.payment_status_id" :options="paymentStatusOptions" text-field="label"
                                :search-empty-item="false"></app-select>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="6" class="mb-4" v-if="checkPermission(C_RESERVATION_ACCOMMODATION_FINANCE_TOTAL_GROSS_E)">
                    <app-input-control :error-object="validation.total_gross">
                        <template v-slot:input="data">
                            <label>{{ $t("TOTAL") }}</label>
                            <app-number-input @input="calculateChangeTotalGross" v-model="formData.total_gross.channel"
                                              :state="data.state"
                                              :append="formData.channel_currency.code"
                                              allow-decimal :min="0" align="right">
                            </app-number-input>
                        </template>
                    </app-input-control>
                </b-col>
                <b-col md="6" class="mb-4" v-if="checkPermission(C_RESERVATION_ACCOMMODATION_FINANCE_TOTAL_NETT_E)">
                    <app-input-control :error-object="validation.total">
                        <template v-slot:input="data">
                            <label>{{ $t("TOTAL_NETT") }}</label>
                            <app-number-input @input="calculateChangeTotal" v-model="formData.total.channel"
                                              :state="data.state"
                                              :append="formData.channel_currency.code"
                                              allow-decimal :min="0" align="right">
                            </app-number-input>
                        </template>
                    </app-input-control>
                </b-col>
                <b-col md="6" class="mb-4" v-if="checkPermission(C_RESERVATION_ACCOMMODATION_FINANCE_OWNER_PART_V)">
                    <label style="white-space: nowrap">{{ $t("OWNER_PART") }}</label>
                    <app-number-input :value="ownerPart"
                                      :append="formData.channel_currency.code"
                                      allow-decimal align="right" disabled>
                    </app-number-input>
                </b-col>
            </b-row>

            <form-header
                v-if="checkPermission(C_RESERVATION_ACCOMMODATION_FINANCE_SALES_CHANNEL_COMMISSION_E) || checkPermission(C_RESERVATION_ACCOMMODATION_FINANCE_SALES_CHANNEL_COMMISSION_VAT_E) || checkPermission(C_RESERVATION_ACCOMMODATION_FINANCE_SALES_CHANNEL_COMMISSION_TOTAL_V)">
                {{ $t('SALES_CHANNEL_COMMISSION') }}
            </form-header>
            <b-row>
                <b-col md="6" class="mb-4"
                       v-if="checkPermission(C_RESERVATION_ACCOMMODATION_FINANCE_SALES_CHANNEL_COMMISSION_E)">
                    <app-input-control :error-object="validation.channel_commission">
                        <template v-slot:input="data">
                            <label style="white-space: nowrap">{{ $t("SALES_CHANNEL_COMMISSION") }}</label>

                            <b-input-group>
                                <b-form-input type="number" min="0" step="0.01"
                                              @input="calculateChannelCommission($event)"
                                              v-model="formData.channel_commission.value"
                                              :state="data.state"></b-form-input>

                                <b-input-group-append>
                                    <app-select :search-empty-item="false"
                                                v-model="formData.channel_commission.type"
                                                :options="relationTypeOptions"
                                                @input="calculateChannelCommission(formData.channel_commission.value)"></app-select>
                                </b-input-group-append>
                            </b-input-group>
                        </template>
                    </app-input-control>
                </b-col>

                <b-col md="6" class="mb-4"
                       v-if="checkPermission(C_RESERVATION_ACCOMMODATION_FINANCE_SALES_CHANNEL_COMMISSION_E)">
                    <label style="white-space: nowrap">{{ $t("SALES_CHANNEL_COMMISSION") }}</label>

                    <app-number-input disabled :value="formData.channel_commission.channel"
                                      :append="formData.channel_currency.code"
                                      allow-decimal :min="0" align="right">
                    </app-number-input>
                </b-col>

                <b-col md="6" class="mb-4"
                       v-if="checkPermission(C_RESERVATION_ACCOMMODATION_FINANCE_SALES_CHANNEL_COMMISSION_VAT_E)">
                    <app-input-control :error-object="validation.channel_vat">
                        <template v-slot:input="data">
                            <label style="white-space: nowrap">{{ $t("VAT_CHANNEL_COMMISSION") }}</label>
                            <b-input-group>
                                <b-form-input
                                    type="number" min="0" step="0.01" @input="calculateVatChannelCommission($event)"
                                    v-model="formData.channel_vat.value" :state="data.state"></b-form-input>
                                <b-input-group-append>
                                    <app-select :search-empty-item="false"
                                                v-model="formData.channel_vat.type"
                                                :options="relationTypeOptions"
                                                @input="calculateVatChannelCommission(formData.channel_vat.value)"></app-select>
                                </b-input-group-append>
                            </b-input-group>
                        </template>
                    </app-input-control>
                </b-col>

                <b-col md="6" class="mb-4"
                       v-if="checkPermission(C_RESERVATION_ACCOMMODATION_FINANCE_SALES_CHANNEL_COMMISSION_VAT_E)">
                    <label style="white-space: nowrap">{{ $t("VAT_CHANNEL_COMMISSION") }}</label>

                    <app-number-input disabled :value="formData.channel_vat.channel"
                                      :append="formData.channel_currency.code"
                                      allow-decimal :min="0" align="right">
                    </app-number-input>
                </b-col>

                <b-col md="6" class="mb-4"
                       v-if="checkPermission(C_RESERVATION_ACCOMMODATION_FINANCE_SALES_CHANNEL_COMMISSION_TOTAL_V)">
                    <app-input-control :error-object="validation.sales_channel_commission_total">
                        <template v-slot:input="data">
                            <label style="white-space: nowrap">{{ $t("SALES_CHANNEL_COMMISSION_TOTAL") }}</label>
                            <app-number-input disabled v-model="formData.sales_channel_commission_total.channel"
                                              :state="data.state"
                                              :append="formData.channel_currency.code"
                                              allow-decimal :min="0" align="right">
                            </app-number-input>
                        </template>
                    </app-input-control>
                </b-col>
            </b-row>

            <form-header
                v-if="checkPermission(C_RESERVATION_ACCOMMODATION_FINANCE_AGENCY_COMMISSION_E) || checkPermission(C_RESERVATION_ACCOMMODATION_FINANCE_FIXED_AGENCY_COMMISSION_E) || checkPermission(C_RESERVATION_ACCOMMODATION_FINANCE_AGENCY_COMMISSION_TOTAL_V)">
                {{ $t('AGENCY_COMMISSION') }}
            </form-header>
            <b-row>
                <b-col md="6" class="mb-4"
                       v-if="checkPermission(C_RESERVATION_ACCOMMODATION_FINANCE_AGENCY_COMMISSION_E)">
                    <app-input-control :error-object="validation.agency_commission">
                        <template v-slot:input="data">
                            <label style="white-space: nowrap">{{ $t("AGENCY_COMMISSION") }}</label>

                            <b-input-group>
                                <b-form-input type="number" min="0" step="0.01"
                                              @input="calculateAgencyCommission($event)"
                                              v-model="formData.agency_commission.value"
                                              :state="data.state"></b-form-input>

                                <b-input-group-append>
                                    <app-select :search-empty-item="false"
                                                v-model="formData.agency_commission.type"
                                                :options="relationTypeOptions"
                                                @input="calculateAgencyCommission(formData.agency_commission.value)"></app-select>
                                </b-input-group-append>
                            </b-input-group>
                        </template>
                    </app-input-control>
                </b-col>

                <b-col md="6" class="mb-4"
                       v-if="checkPermission(C_RESERVATION_ACCOMMODATION_FINANCE_AGENCY_COMMISSION_E)">
                    <label style="white-space: nowrap">{{ $t("AGENCY_COMMISSION") }}</label>

                    <app-number-input disabled :value="formData.agency_commission.channel"
                                      :append="formData.channel_currency.code"
                                      allow-decimal :min="0" align="right">
                    </app-number-input>
                </b-col>

                <b-col md="6" class="mb-4"
                       v-if="checkPermission(C_RESERVATION_ACCOMMODATION_FINANCE_FIXED_AGENCY_COMMISSION_E)">
                    <app-input-control :error-object="validation.agency_commission">
                        <template v-slot:input="data">
                            <label style="white-space: nowrap">{{ $t("FIXED_AGENCY_COMMISSION") }}</label>
                            <app-number-input @input="calculateFixedAgencyCommission"
                                              v-model="formData.agency_fixed_commission.channel" :state="data.state"
                                              :append="formData.channel_currency.code"
                                              allow-decimal :min="0" align="right">
                            </app-number-input>
                        </template>
                    </app-input-control>
                </b-col>
            </b-row>

            <b-row>
                <b-col md="6" class="mb-4"
                       v-if="checkPermission(C_RESERVATION_ACCOMMODATION_FINANCE_AGENCY_COMMISSION_TOTAL_V)">
                    <app-input-control :error-object="validation.agency_commission">
                        <template v-slot:input="data">
                            <label style="white-space: nowrap">{{ $t("AGENCY_COMMISSION_TOTAL") }}</label>
                            <app-number-input disabled v-model="formData.agency_commission_total.channel"
                                              :state="data.state"
                                              :append="formData.channel_currency.code"
                                              allow-decimal :min="0" align="right">
                            </app-number-input>
                        </template>
                    </app-input-control>
                </b-col>
            </b-row>

            <form-header
                v-if="checkPermission(C_RESERVATION_ACCOMMODATION_FINANCE_DISTRIBUTION_COMMISSION_E) || checkPermission(C_RESERVATION_ACCOMMODATION_FINANCE_DISTRIBUTION_COMMISSION_VAT_E) || checkPermission(C_RESERVATION_ACCOMMODATION_FINANCE_DISTRIBUTION_COMMISSION_TOTAL_V)">
                {{ $t('DISTRIBUTION_COMMISSION') }}
            </form-header>
            <b-row>
                <b-col md="6" class="mb-4"
                       v-if="checkPermission(C_RESERVATION_ACCOMMODATION_FINANCE_DISTRIBUTION_COMMISSION_E)">
                    <app-input-control :error-object="validation.distribution_commission">
                        <template v-slot:input="data">
                            <label style="white-space: nowrap">{{ $t("DISTRIBUTION_COMMISSION") }}</label>

                            <b-input-group>
                                <b-form-input type="number" min="0" step="0.01"
                                              @input="calculateDistributionCommission($event)"
                                              v-model="formData.distribution_commission.value"
                                              :state="data.state"></b-form-input>

                                <b-input-group-append>
                                    <app-select :search-empty-item="false"
                                                v-model="formData.distribution_commission.type"
                                                :options="relationTypeOptions"
                                                @input="calculateDistributionCommission(formData.distribution_commission.value)"></app-select>
                                </b-input-group-append>
                            </b-input-group>
                        </template>
                    </app-input-control>
                </b-col>
                <b-col md="6" class="mb-4"
                       v-if="checkPermission(C_RESERVATION_ACCOMMODATION_FINANCE_DISTRIBUTION_COMMISSION_E)">
                    <label style="white-space: nowrap">{{ $t("DISTRIBUTION_COMMISSION") }}</label>

                    <app-number-input disabled :value="formData.distribution_commission.channel"
                                      :append="formData.channel_currency.code"
                                      allow-decimal :min="0" align="right">
                    </app-number-input>
                </b-col>

                <b-col md="6" class="mb-4"
                       v-if="checkPermission(C_RESERVATION_ACCOMMODATION_FINANCE_DISTRIBUTION_COMMISSION_VAT_E)">
                    <app-input-control :error-object="validation.distribution_commission_vat">
                        <template v-slot:input="data">
                            <label style="white-space: nowrap">{{ $t("VAT_DISTRIBUTION_COMMISSION") }}</label>
                            <b-input-group>
                                <b-form-input
                                    type="number" min="0" step="0.01"
                                    @input="calculateVatDistributionCommission($event)"
                                    v-model="formData.distribution_commission_vat.value"
                                    :state="data.state"></b-form-input>
                                <b-input-group-append>
                                    <app-select
                                        :search-empty-item="false"
                                        v-model="formData.distribution_commission_vat.type"
                                        :options="relationTypeOptions"
                                        @input="calculateVatDistributionCommission(formData.distribution_commission_vat.value)"></app-select>
                                </b-input-group-append>
                            </b-input-group>
                        </template>
                    </app-input-control>
                </b-col>
                <b-col md="6" class="mb-4"
                       v-if="checkPermission(C_RESERVATION_ACCOMMODATION_FINANCE_DISTRIBUTION_COMMISSION_VAT_E)">
                    <label style="white-space: nowrap">{{ $t("VAT_DISTRIBUTION_COMMISSION") }}</label>

                    <app-number-input disabled :value="formData.distribution_commission_vat.channel"
                                      :append="formData.channel_currency.code"
                                      allow-decimal :min="0" align="right">
                    </app-number-input>
                </b-col>
                <b-col md="6" class="mb-4"
                       v-if="checkPermission(C_RESERVATION_ACCOMMODATION_FINANCE_DISTRIBUTION_COMMISSION_TOTAL_V)">
                    <app-input-control :error-object="validation.distribution_commission_total">
                        <template v-slot:input="data">
                            <label style="white-space: nowrap">{{ $t("DISTRIBUTION_COMMISSION_TOTAL") }}</label>
                            <app-number-input disabled v-model="formData.distribution_commission_total.channel"
                                              :state="data.state"
                                              :append="formData.channel_currency.code"
                                              allow-decimal :min="0" align="right">
                            </app-number-input>
                        </template>
                    </app-input-control>
                </b-col>
            </b-row>

            <form-header
                v-if="checkPermission(C_RESERVATION_ACCOMMODATION_FINANCE_ADDITIONAL_COMMISSION_E) || checkPermission(C_RESERVATION_ACCOMMODATION_FINANCE_ADDITIONAL_COMMISSION_VAT_E) || checkPermission(C_RESERVATION_ACCOMMODATION_FINANCE_ADDITIONAL_COMMISSION_TOTAL_V)">
                {{ $t('ADDITIONAL_COMMISSION') }}
            </form-header>
            <b-row>
                <b-col md="6" class="mb-4"
                       v-if="checkPermission(C_RESERVATION_ACCOMMODATION_FINANCE_ADDITIONAL_COMMISSION_E)">
                    <app-input-control :error-object="validation.additional_commission">
                        <template v-slot:input="data">
                            <label style="white-space: nowrap">{{ $t("ADDITIONAL_COMMISSION") }}</label>

                            <b-input-group>
                                <b-form-input type="number" min="0" step="0.01"
                                              @input="calculateAdditionalCommission($event)"
                                              v-model="formData.additional_commission.value"
                                              :state="data.state"></b-form-input>

                                <b-input-group-append>
                                    <app-select :search-empty-item="false"
                                                v-model="formData.additional_commission.type"
                                                :options="relationTypeOptions"
                                                @input="calculateAdditionalCommission(formData.additional_commission.value)"></app-select>
                                </b-input-group-append>
                            </b-input-group>
                        </template>
                    </app-input-control>
                </b-col>
                <b-col md="6" class="mb-4"
                       v-if="checkPermission(C_RESERVATION_ACCOMMODATION_FINANCE_ADDITIONAL_COMMISSION_E)">
                    <label style="white-space: nowrap">{{ $t("ADDITIONAL_COMMISSION") }}</label>

                    <app-number-input disabled :value="formData.additional_commission.channel"
                                      :append="formData.channel_currency.code"
                                      allow-decimal :min="0" align="right">
                    </app-number-input>
                </b-col>

                <b-col md="6" class="mb-4"
                       v-if="checkPermission(C_RESERVATION_ACCOMMODATION_FINANCE_ADDITIONAL_COMMISSION_VAT_E)">
                    <app-input-control :error-object="validation.additional_commission_vat">
                        <template v-slot:input="data">
                            <label style="white-space: nowrap">{{ $t("VAT_ADDITIONAL_COMMISSION") }}</label>
                            <b-input-group>
                                <b-form-input
                                    type="number" min="0" step="0.01" @input="calculateVatAdditionalCommission($event)"
                                    v-model="formData.additional_commission_vat.value"
                                    :state="data.state"></b-form-input>
                                <b-input-group-append>
                                    <app-select :search-empty-item="false"
                                                v-model="formData.additional_commission_vat.type"
                                                :options="relationTypeOptions"
                                                @input="calculateVatAdditionalCommission(formData.additional_commission_vat.value)"></app-select>
                                </b-input-group-append>
                            </b-input-group>
                        </template>
                    </app-input-control>
                </b-col>
                <b-col md="6" class="mb-4"
                       v-if="checkPermission(C_RESERVATION_ACCOMMODATION_FINANCE_ADDITIONAL_COMMISSION_VAT_E)">
                    <label style="white-space: nowrap">{{ $t("VAT_ADDITIONAL_COMMISSION") }}</label>

                    <app-number-input disabled :value="formData.additional_commission_vat.channel"
                                      :append="formData.channel_currency.code"
                                      allow-decimal :min="0" align="right">
                    </app-number-input>
                </b-col>
                <b-col md="6" class="mb-4"
                       v-if="checkPermission(C_RESERVATION_ACCOMMODATION_FINANCE_ADDITIONAL_COMMISSION_TOTAL_V)">
                    <app-input-control :error-object="validation.additional_commission_total">
                        <template v-slot:input="data">
                            <label style="white-space: nowrap">{{ $t("ADDITIONAL_COMMISSION_TOTAL") }}</label>
                            <app-number-input disabled v-model="formData.additional_commission_total.channel"
                                              :state="data.state"
                                              :append="formData.channel_currency.code"
                                              allow-decimal :min="0" align="right">
                            </app-number-input>
                        </template>
                    </app-input-control>
                </b-col>
            </b-row>

            <form-header
                v-if="checkPermission(C_RESERVATION_ACCOMMODATION_FINANCE_MARKETING_COMMISSION_E) || checkPermission(C_RESERVATION_ACCOMMODATION_FINANCE_MARKETING_COMMISSION_VAT_E) || checkPermission(C_RESERVATION_ACCOMMODATION_FINANCE_MARKETING_COMMISSION_TOTAL_V)">
                {{ $t('MARKETING_COMMISSION') }}
            </form-header>
            <b-row>
                <b-col md="6" class="mb-4"
                       v-if="checkPermission(C_RESERVATION_ACCOMMODATION_FINANCE_MARKETING_COMMISSION_E)">
                    <app-input-control :error-object="validation.marketing_commission">
                        <template v-slot:input="data">
                            <label style="white-space: nowrap">{{ $t("MARKETING_COMMISSION") }}</label>

                            <b-input-group>
                                <b-form-input type="number" min="0" step="0.01"
                                              @input="calculateMarketingCommission($event)"
                                              v-model="formData.marketing_commission.value"
                                              :state="data.state">
                                </b-form-input>
                                <b-input-group-append>
                                    <app-select :search-empty-item="false"
                                                v-model="formData.marketing_commission.type"
                                                :options="relationTypeOptions"
                                                @input="calculateMarketingCommission(formData.marketing_commission.value)"></app-select>
                                </b-input-group-append>
                            </b-input-group>
                        </template>
                    </app-input-control>
                </b-col>
                <b-col md="6" class="mb-4"
                       v-if="checkPermission(C_RESERVATION_ACCOMMODATION_FINANCE_MARKETING_COMMISSION_E)">
                    <label style="white-space: nowrap">{{ $t("MARKETING_COMMISSION") }}</label>

                    <app-number-input disabled :value="formData.marketing_commission.channel"
                                      :append="formData.channel_currency.code"
                                      allow-decimal :min="0" align="right">
                    </app-number-input>
                </b-col>

                <b-col md="6" class="mb-4"
                       v-if="checkPermission(C_RESERVATION_ACCOMMODATION_FINANCE_MARKETING_COMMISSION_VAT_E)">
                    <app-input-control :error-object="validation.marketing_commission_vat">
                        <template v-slot:input="data">
                            <label style="white-space: nowrap">{{ $t("MARKETING_COMMISSION_VAT") }}</label>
                            <b-input-group>
                                <b-form-input
                                    type="number" min="0" step="0.01" @input="calculateVatMarketingCommission($event)"
                                    v-model="formData.marketing_commission_vat.value"
                                    :state="data.state">
                                </b-form-input>
                                <b-input-group-append>
                                    <app-select :search-empty-item="false"
                                                v-model="formData.marketing_commission_vat.type"
                                                :options="relationTypeOptions"
                                                @input="calculateVatMarketingCommission(formData.marketing_commission_vat.value)"></app-select>
                                </b-input-group-append>
                            </b-input-group>
                        </template>
                    </app-input-control>
                </b-col>
                <b-col md="6" class="mb-4"
                       v-if="checkPermission(C_RESERVATION_ACCOMMODATION_FINANCE_MARKETING_COMMISSION_VAT_E)">
                    <label style="white-space: nowrap">{{ $t("MARKETING_COMMISSION_VAT") }}</label>

                    <app-number-input disabled :value="formData.marketing_commission_vat.channel"
                                      :append="formData.channel_currency.code"
                                      allow-decimal :min="0" align="right">
                    </app-number-input>
                </b-col>
                <b-col md="6" class="mb-4"
                       v-if="checkPermission(C_RESERVATION_ACCOMMODATION_FINANCE_MARKETING_COMMISSION_TOTAL_V)">
                    <app-input-control :error-object="validation.marketing_commission_total">
                        <template v-slot:input="data">
                            <label style="white-space: nowrap">{{ $t("MARKETING_COMMISSION_TOTAL") }}</label>
                            <app-number-input disabled v-model="formData.marketing_commission_total.channel"
                                              :state="data.state"
                                              :append="formData.channel_currency.code"
                                              allow-decimal :min="0" align="right">
                            </app-number-input>
                        </template>
                    </app-input-control>
                </b-col>
            </b-row>

            <template v-if="checkPermission(C_RESERVATION_ACCOMMODATION_FINANCE_TRANSACTION_FEE_E)">
                <form-header>{{ $t("TRANSACTION_FEE") }}</form-header>
                <b-row>
                    <b-col md="6" class="mb-4">
                        <label>{{ $t("TRANSACTION_FEE") }}</label>

                        <b-input-group>
                            <b-form-input type="number" min="0" step="0.01"
                                          @input="calculateTransactionCommission($event)"
                                          v-model="formData.bank_commission.value"></b-form-input>
                            <b-input-group-append>
                                <app-select :search-empty-item="false"
                                            v-model="formData.bank_commission.type"
                                            :options="relationTypeOptions"
                                            @input="calculateTransactionCommission(formData.bank_commission.value)"></app-select>
                            </b-input-group-append>
                        </b-input-group>
                    </b-col>
                    <b-col md="6" class="mb-4">
                        <label>{{ $t("TRANSACTION_FEE") }}</label>

                        <app-number-input disabled :value="formData.bank_commission.channel"
                                          :append="formData.channel_currency.code"
                                          allow-decimal :min="0" align="right">
                        </app-number-input>
                    </b-col>
                </b-row>
            </template>
            <template v-if="checkPermission(C_RESERVATION_ACCOMMODATION_FINANCE_PLATFORM_FEE_E) && platformFeeEnabled">
                <form-header>{{ $t("BOOKING_FEE") }}</form-header>
                <b-row>
                    <b-col md="6" class="mb-4">
                        <label>{{ $t("BOOKING_FEE") }}</label>

                        <b-input-group>
                            <b-form-input type="number" min="0" step="0.01"
                                          @input="calculatePlatformCommission($event)"
                                          v-model="formData.platform_fee_type.value"></b-form-input>
                            <b-input-group-append>
                                <app-select :search-empty-item="false"
                                            v-model="formData.platform_fee_type.type"
                                            :options="relationTypeOptions"
                                            @input="calculatePlatformCommission(formData.platform_fee_type.value)"></app-select>
                            </b-input-group-append>
                        </b-input-group>
                    </b-col>
                    <b-col md="6" class="mb-4">
                        <label>{{ $t("BOOKING_FEE") }}</label>

                        <app-number-input disabled :value="formData.platform_fee.channel"
                                          :append="formData.channel_currency.code"
                                          allow-decimal :min="0" align="right">
                        </app-number-input>
                    </b-col>
                </b-row>
            </template>

            <b-row>
                <b-col>
                    <app-button-submit :loading="saving"></app-button-submit>
                </b-col>
            </b-row>
        </b-form>
    </div>

</template>

<script>
import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
import AppSelect from "@/components/app/AppSelect/AppSelect";
import AppInputControl from "@/components/app/AppInputControl";
import AppNumberInput from "@/components/app/form/AppNumberInput";
import {getErrorMessage} from "@/mixins/error/getErrorMessage";
import {
    fetchAccommodationReservationFinance,
    fetchFinanceValues,
    updateAccommodationReservationFinance
} from "@/services/accommodation_reservation";
import {notifySuccess} from "@/shared/plugins/toastr";
import {
    FIX,
    PERC,
    OBJECT_TYPE_ACCOMMODATION_RESERVATION_FINANCE,
    RATE_PLAN_CATEGORY_DISTRIBUTION,
} from "@/shared/constants";
import {cloneDeep} from "lodash";
import FormHeader from "@/components/app/form/FormHeader";
import {getStatusList} from "@/services/status";
import AppButton from "@/components/app/AppButton/AppButton";
import {
    C_RESERVATION_ACCOMMODATION_FINANCE_TOTAL_GROSS_E,
    C_RESERVATION_ACCOMMODATION_FINANCE_TOTAL_NETT_E,
    C_RESERVATION_ACCOMMODATION_FINANCE_SALES_CHANNEL_COMMISSION_E,
    C_RESERVATION_ACCOMMODATION_FINANCE_SALES_CHANNEL_COMMISSION_VAT_E,
    C_RESERVATION_ACCOMMODATION_FINANCE_AGENCY_COMMISSION_E,
    C_RESERVATION_ACCOMMODATION_FINANCE_FIXED_AGENCY_COMMISSION_E,
    C_RESERVATION_ACCOMMODATION_FINANCE_DISTRIBUTION_COMMISSION_E,
    C_RESERVATION_ACCOMMODATION_FINANCE_DISTRIBUTION_COMMISSION_VAT_E,
    C_RESERVATION_ACCOMMODATION_FINANCE_ADDITIONAL_COMMISSION_E,
    C_RESERVATION_ACCOMMODATION_FINANCE_ADDITIONAL_COMMISSION_VAT_E,
    C_RESERVATION_ACCOMMODATION_FINANCE_TRANSACTION_FEE_E,
    C_RESERVATION_ACCOMMODATION_FINANCE_OWNER_PART_V,
    C_RESERVATION_ACCOMMODATION_FINANCE_SALES_CHANNEL_COMMISSION_TOTAL_V,
    C_RESERVATION_ACCOMMODATION_FINANCE_AGENCY_COMMISSION_TOTAL_V,
    C_RESERVATION_ACCOMMODATION_FINANCE_DISTRIBUTION_COMMISSION_TOTAL_V,
    C_RESERVATION_ACCOMMODATION_FINANCE_ADDITIONAL_COMMISSION_TOTAL_V,
    C_RESERVATION_ACCOMMODATION_FINANCE_RATE_PLAN_E,
    C_RESERVATION_ACCOMMODATION_FINANCE_PAYMENT_STATUS_E,
    C_RESERVATION_ACCOMMODATION_FINANCE_FETCH_FINANCIAL_VALUES,
    C_RESERVATION_ACCOMMODATION_FINANCE_PLATFORM_FEE_E,
    C_RESERVATION_ACCOMMODATION_FINANCE_MARKETING_COMMISSION_TOTAL_V,
    C_RESERVATION_ACCOMMODATION_FINANCE_MARKETING_COMMISSION_E,
    C_RESERVATION_ACCOMMODATION_FINANCE_MARKETING_COMMISSION_VAT_E,
} from "@/shared/component_permission";
import {getRatePlanList} from "@/services/pricing";
import {
    AC_ACCOMMODATION_RESERVATION_FINANCE_DETAILS,
} from "@/mixins/AccessControl/AccessControlEnumeration";
import AppAccessControl from "@/components/app/AppAccessControl";
import {AccessControlComponent} from "@/mixins/AccessControl/AccessControlComponent";
import {WORKFLOW_DIRECT_BOOKER, DIRECT_BOOKER_COMPANY} from "@/shared/constants";

const DECIMAL_PLACES = 2

export default {
    name: "FinanceDetailsEditForm",
    components: {AppAccessControl, AppButton, FormHeader, AppButtonSubmit, AppSelect, AppInputControl, AppNumberInput},
    mixins: [getErrorMessage, AccessControlComponent],
    props: {
        reservation: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            validation: {
                total_gross: {},
                total: {},
                channel_commission: {},
                channel_vat: {},
                sales_channel_commission_total: {},
                agency_commission: {},
                agency_commission_total: {},
                distribution_commission: {},
                distribution_commission_vat: {},
                distribution_commission_total: {},
                additional_commission: {},
                additional_commission_vat: {},
                additional_commission_total: {},
                marketing_commission: {},
                marketing_commission_vat: {},
                marketing_commission_total: {},
                bank_commission: {}
            },
            componentFormNumberInputKey: 0,
            formDataFormulasEnabled: false,
            formData: null,
            saving: false,
            FIX,
            PERC,
            relationTypeOptions: [
                {id: PERC, name: '%'},
                {id: FIX, name: 'fix'}
            ],
            paymentStatusOptions: [],
            fetchingFinancialValues: false,
            ratePlanList: [],
            C_RESERVATION_ACCOMMODATION_FINANCE_TOTAL_GROSS_E,
            C_RESERVATION_ACCOMMODATION_FINANCE_TOTAL_NETT_E,
            C_RESERVATION_ACCOMMODATION_FINANCE_SALES_CHANNEL_COMMISSION_E,
            C_RESERVATION_ACCOMMODATION_FINANCE_SALES_CHANNEL_COMMISSION_VAT_E,
            C_RESERVATION_ACCOMMODATION_FINANCE_AGENCY_COMMISSION_E,
            C_RESERVATION_ACCOMMODATION_FINANCE_FIXED_AGENCY_COMMISSION_E,
            C_RESERVATION_ACCOMMODATION_FINANCE_DISTRIBUTION_COMMISSION_E,
            C_RESERVATION_ACCOMMODATION_FINANCE_DISTRIBUTION_COMMISSION_VAT_E,
            C_RESERVATION_ACCOMMODATION_FINANCE_ADDITIONAL_COMMISSION_E,
            C_RESERVATION_ACCOMMODATION_FINANCE_ADDITIONAL_COMMISSION_VAT_E,
            C_RESERVATION_ACCOMMODATION_FINANCE_MARKETING_COMMISSION_E,
            C_RESERVATION_ACCOMMODATION_FINANCE_MARKETING_COMMISSION_VAT_E,
            C_RESERVATION_ACCOMMODATION_FINANCE_TRANSACTION_FEE_E,
            C_RESERVATION_ACCOMMODATION_FINANCE_OWNER_PART_V,
            C_RESERVATION_ACCOMMODATION_FINANCE_SALES_CHANNEL_COMMISSION_TOTAL_V,
            C_RESERVATION_ACCOMMODATION_FINANCE_AGENCY_COMMISSION_TOTAL_V,
            C_RESERVATION_ACCOMMODATION_FINANCE_DISTRIBUTION_COMMISSION_TOTAL_V,
            C_RESERVATION_ACCOMMODATION_FINANCE_ADDITIONAL_COMMISSION_TOTAL_V,
            C_RESERVATION_ACCOMMODATION_FINANCE_MARKETING_COMMISSION_TOTAL_V,
            C_RESERVATION_ACCOMMODATION_FINANCE_RATE_PLAN_E,
            C_RESERVATION_ACCOMMODATION_FINANCE_PAYMENT_STATUS_E,
            C_RESERVATION_ACCOMMODATION_FINANCE_FETCH_FINANCIAL_VALUES,
            C_RESERVATION_ACCOMMODATION_FINANCE_PLATFORM_FEE_E,
            DIRECT_BOOKER_COMPANY,
            access_control_fetch_key: AC_ACCOMMODATION_RESERVATION_FINANCE_DETAILS,
            access_control_components: 1,
            ratePlanOptionVisible: false,
        }
    },
    computed: {
        company() {
            return this.$store.getters['user/getCurrentCompany']
        },
        ownerPart() {
            const value = Number(this.formData.total_gross.channel) -
                (Number(this.formData.agency_commission_total.channel) +
                    Number(this.formData.sales_channel_commission_total.channel) +
                    Number(this.formData.bank_commission.channel) +
                    Number(this.formData.distribution_commission_total.channel) +
                    Number(this.formData.additional_commission_total.channel) +
                    Number(this.formData.marketing_commission_total.channel) +
                    (
                        this.formData.hasOwnProperty("platform_fee") && !!this.formData.platform_fee && this.formData.platform_fee.hasOwnProperty("channel")
                            ? Number(this.formData.platform_fee.channel) : 0
                    )
                )
            return value < 0 ? 0 : value
        },
        platformFeeEnabled() {
            return this.company.id === DIRECT_BOOKER_COMPANY &&
                this.formData.hasOwnProperty("platform_fee") && !!this.formData.platform_fee && this.formData.hasOwnProperty("platform_fee_type") && !!this.formData.platform_fee_type
        },
    },
    watch: {
        'formData.rate_plan': {
            handler(value, oldValue) {
                if (((oldValue === null || oldValue === undefined) && value === this.reservation.rate_plan.id) || value === null) {
                    return
                }
                this.fetchFinanceValues(false)
            },
        },
    },
    methods: {
        forceReRender() {
            this.componentFormNumberInputKey += 1
        },
        calculateChangeTotal() {
            if (!this.formDataFormulasEnabled) {
                return
            }
            this.formData.total_gross.channel = this.applyChannelCommission(this.formData.total.channel)
            this.calculateChannelCommission(this.formData.channel_commission.value)
            this.calculateAgencyCommission(this.formData.agency_commission.value)
            this.calculateTransactionCommission(this.formData.bank_commission.value)
            if (this.formData.hasOwnProperty("platform_fee_type")) {
                this.calculatePlatformCommission(this.formData.platform_fee_type.value)
            }
            this.calculateAdditionalCommission(this.formData.additional_commission.value)
            this.calculateDistributionCommission(this.formData.distribution_commission.value)
            this.calculateMarketingCommission(this.formData.marketing_commission.value)
        },
        calculateChangeTotalGross() {
            if (!this.formDataFormulasEnabled) {
                return
            }
            this.formData.total.channel = this.applyChannelCommission(this.formData.total_gross.channel, false)
            this.calculateChannelCommission(this.formData.channel_commission.value)
            this.calculateAgencyCommission(this.formData.agency_commission.value)
            this.calculateTransactionCommission(this.formData.bank_commission.value)
            if (this.formData.hasOwnProperty("platform_fee_type")) {
                this.calculatePlatformCommission(this.formData.platform_fee_type.value)
            }
            this.calculateAdditionalCommission(this.formData.additional_commission.value)
            this.calculateDistributionCommission(this.formData.distribution_commission.value)
            this.calculateMarketingCommission(this.formData.marketing_commission.value)
        },
        applyChannelCommission(value, increase = true) {
            value = Number(value)
            if (this.formData === null || !this.formData.hasOwnProperty('channel_commission') || this.formData.channel_commission.type === null) {
                return value
            }
            if (this.formData.channel_commission.type === FIX && this.formData.total_gross.channel !== 0) {
                return increase ? value + Number(this.formData.channel_commission.value) : value - Number(this.formData.channel_commission.value)
            }
            return increase ? value / (1 - (Number(this.formData.channel_commission.value)) / 100) :
                value * (1 - Number(this.formData.channel_commission.value) / 100)
        },
        removeValueAlInput() {
            this.formData.channel_commission.channel = 0
            this.formData.channel_commission.value = 0
            this.formData.channel_vat.channel = 0
            this.formData.channel_vat.value = 0
            this.formData.sales_channel_commission_total.channel = 0
            this.formData.agency_commission.channel = 0
            this.formData.agency_commission.value = 0
            this.formData.agency_commission_total.channel = 0
            this.formData.agency_fixed_commission.channel = 0
            this.formData.distribution_commission.value = 0
            this.formData.distribution_commission.channel = 0
            this.formData.distribution_commission_vat.value = 0
            this.formData.distribution_commission_vat.channel = 0
            this.formData.distribution_commission_total.channel = 0
            this.formData.additional_commission.value = 0
            this.formData.additional_commission.channel = 0
            this.formData.additional_commission_vat.value = 0
            this.formData.additional_commission_vat.channel = 0
            this.formData.additional_commission_total.channel = 0
            this.formData.marketing_commission.value = 0
            this.formData.marketing_commission.channel = 0
            this.formData.marketing_commission_vat.value = 0
            this.formData.marketing_commission_vat.channel = 0
            this.formData.marketing_commission_total.channel = 0
            this.formData.bank_commission.channel = 0
            this.formData.bank_commission.value = 0
            if (this.formData.hasOwnProperty("platform_fee")) {
                this.formData.platform_fee.channel = 0
            }
            if (this.formData.hasOwnProperty("platform_fee_type")) {
                this.formData.platform_fee_type.value = 0
            }
        },
        calculateTransactionCommission(value) {
            if (!this.formDataFormulasEnabled) {
                return
            }
            if (this.formData.bank_commission.type !== null) {
                if (this.formData.bank_commission.type === FIX && this.formData.total_gross.channel !== 0) {
                    this.formData.bank_commission.channel = value
                } else {
                    const calValue = (Number(value) / 100) * this.formData.total_gross.channel
                    this.formData.bank_commission.channel = calValue.toFixed(DECIMAL_PLACES)
                }
            }
        },
        calculatePlatformCommission(value) {
            if (!this.formDataFormulasEnabled) {
                return
            }
            if (this.formData.platform_fee_type.type !== null) {
                if (this.formData.platform_fee_type.type === FIX && this.formData.total_gross.channel !== 0) {
                    this.formData.platform_fee.channel = value
                } else {
                    const calValue = (Number(value) / 100) * this.formData.total_gross.channel
                    this.formData.platform_fee.channel = calValue.toFixed(DECIMAL_PLACES)
                }
            }
        },
        calculateVatChannelCommission(value) {
            if (!this.formDataFormulasEnabled) {
                return
            }
            if (this.formData.channel_vat.type !== null) {
                if (this.formData.channel_vat.type === FIX) {
                    this.formData.channel_vat.channel = value
                } else {
                    const calValue = (Number(value) / 100) * this.formData.channel_commission.channel
                    this.formData.channel_vat.channel = calValue.toFixed(DECIMAL_PLACES)
                }
                this.formData.sales_channel_commission_total.channel = this.formData.total_gross.channel === 0 ? 0 : Number(this.formData.channel_commission.channel) + Number(this.formData.channel_vat.channel)
                this.formData.total.channel = this.applyChannelCommission(this.formData.total_gross.channel, false)
            }
        },
        calculateChannelCommission(value) {
            if (!this.formDataFormulasEnabled) {
                return
            }
            if (!value || Number(value) === 0) {
                this.formData.channel_commission.channel = 0
                this.formData.channel_commission.value = 0
                this.formData.sales_channel_commission_total.channel = 0
                this.formData.channel_vat.value = 0
                this.formData.channel_vat.channel = 0
                this.formData.total.channel = this.applyChannelCommission(this.formData.total_gross.channel, false)
                return
            }

            if (this.formData.channel_commission.type !== null) {
                if (this.formData.channel_commission.type === FIX) {
                    this.formData.channel_commission.channel = value
                } else {
                    const calValue = (Number(value) / 100) * Number(this.formData.total_gross.channel)
                    this.formData.channel_commission.channel = calValue.toFixed(DECIMAL_PLACES)
                }

                this.formData.sales_channel_commission_total.channel = this.formData.total_gross.channel === 0 ? 0 : Number(this.formData.channel_commission.channel) + Number(this.formData.channel_vat.channel)
                this.calculateVatChannelCommission(this.formData.channel_vat.value)
            }
        },
        calculateAgencyCommission(value) {
            if (!this.formDataFormulasEnabled) {
                return
            }
            if (this.formData.agency_commission.type !== null) {
                if (this.formData.agency_commission.type === FIX) {
                    this.formData.agency_commission.channel = value
                } else {
                    const calValue = (Number(value) / 100) * Number(this.formData.total_gross.channel)
                    this.formData.agency_commission.channel = calValue.toFixed(DECIMAL_PLACES)
                }
                this.formData.agency_commission_total.channel = this.formData.total_gross.channel === 0 ? 0 : Number(this.formData.agency_commission.channel) + Number(this.formData.agency_fixed_commission.channel)
            }
        },
        calculateFixedAgencyCommission(value) {
            if (!this.formDataFormulasEnabled) {
                return
            }
            this.formData.agency_commission_total.channel = Number(value) + Number(this.formData.agency_commission.channel)
        },
        calculateVatDistributionCommission(value) {
            if (!this.formDataFormulasEnabled) {
                return
            }
            if (this.formData.distribution_commission_vat.type !== null) {
                if (this.formData.distribution_commission_vat.type === FIX) {
                    this.formData.distribution_commission_vat.channel = value
                } else {
                    const calValue = (Number(value) / 100) * Number(this.formData.distribution_commission.channel)
                    this.formData.distribution_commission_vat.channel = calValue.toFixed(DECIMAL_PLACES)
                }
                this.formData.distribution_commission_total.channel = this.formData.total_gross.channel === 0 ? 0 : Number(this.formData.distribution_commission.channel) + Number(this.formData.distribution_commission_vat.channel)
            }
        },
        calculateDistributionCommission(value) {
            if (!this.formDataFormulasEnabled) {
                return
            }
            if (!value || Number(value) === 0) {
                this.formData.distribution_commission.channel = 0
                this.formData.distribution_commission.value = 0
                this.formData.distribution_commission_total.channel = 0
                this.formData.distribution_commission_vat.value = 0
                this.formData.distribution_commission_vat.channel = 0
                return
            }

            if (this.formData.distribution_commission.type !== null) {
                if (this.formData.distribution_commission.type === FIX) {
                    this.formData.distribution_commission.channel = value
                } else {
                    const calValue = (Number(value) / 100) * Number(this.formData.total_gross.channel)
                    this.formData.distribution_commission.channel = calValue.toFixed(DECIMAL_PLACES)
                }
            }

            this.formData.distribution_commission_total.channel = this.formData.total_gross.channel === 0 ? 0 : Number(this.formData.distribution_commission.channel) + Number(this.formData.distribution_commission_vat.channel)
            this.calculateVatDistributionCommission(this.formData.distribution_commission_vat.value)
        },
        calculateVatAdditionalCommission(value) {
            if (!this.formDataFormulasEnabled) {
                return
            }
            if (this.formData.additional_commission_vat.type !== null) {
                if (this.formData.additional_commission_vat.type === FIX) {
                    this.formData.additional_commission_vat.channel = value
                } else {
                    const calValue = (Number(value) / 100) * Number(this.formData.additional_commission.channel)
                    this.formData.additional_commission_vat.channel = calValue.toFixed(DECIMAL_PLACES)
                }
                this.formData.additional_commission_total.channel = this.formData.total_gross.channel === 0 ? 0 : Number(this.formData.additional_commission.channel) + Number(this.formData.additional_commission_vat.channel)
            }
        },
        calculateAdditionalCommission(value) {
            if (!this.formDataFormulasEnabled) {
                return
            }
            if (!value || Number(value) === 0) {
                this.formData.additional_commission.channel = 0
                this.formData.additional_commission.value = 0
                this.formData.additional_commission_total.channel = 0
                this.formData.additional_commission_vat.value = 0
                this.formData.additional_commission_vat.channel = 0
                return
            }

            if (this.formData.additional_commission.type !== null) {
                if (this.formData.additional_commission.type === FIX) {
                    this.formData.additional_commission.channel = value
                } else {
                    const calValue = (Number(value) / 100) * Number(this.formData.total_gross.channel)
                    this.formData.additional_commission.channel = calValue.toFixed(DECIMAL_PLACES)
                }

                this.formData.additional_commission_total.channel = this.formData.total_gross.channel === 0 ? 0 : Number(this.formData.additional_commission.channel) + Number(this.formData.additional_commission_vat.channel)
                this.calculateVatAdditionalCommission(this.formData.additional_commission_vat.value)
            }
        },
        calculateVatMarketingCommission(value) {
            if (!this.formDataFormulasEnabled) {
                return
            }
            if (this.formData.marketing_commission_vat.type !== null) {
                if (this.formData.marketing_commission_vat.type === FIX) {
                    this.formData.marketing_commission_vat.channel = value
                } else {
                    const calValue = (Number(value) / 100) * Number(this.formData.marketing_commission.channel)
                    this.formData.marketing_commission_vat.channel = calValue.toFixed(DECIMAL_PLACES)
                }
                this.formData.marketing_commission_total.channel = this.formData.total_gross.channel === 0 ? 0 : Number(this.formData.marketing_commission.channel) + Number(this.formData.marketing_commission_vat.channel)
            }
        },
        calculateMarketingCommission(value) {
            if (!this.formDataFormulasEnabled) {
                return
            }
            if (!value || Number(value) === 0) {
                this.formData.marketing_commission.channel = 0
                this.formData.marketing_commission.value = 0
                this.formData.marketing_commission_total.channel = 0
                this.formData.marketing_commission_vat.value = 0
                this.formData.marketing_commission_vat.channel = 0
                return
            }

            if (this.formData.marketing_commission.type !== null) {
                if (this.formData.marketing_commission.type === FIX) {
                    this.formData.marketing_commission.channel = value
                } else {
                    const calValue = (Number(value) / 100) * Number(this.formData.total_gross.channel)
                    this.formData.marketing_commission.channel = calValue.toFixed(DECIMAL_PLACES)
                }

                this.formData.marketing_commission_total.channel = this.formData.total_gross.channel === 0 ? 0 : Number(this.formData.marketing_commission.channel) + Number(this.formData.marketing_commission_vat.channel)
                this.calculateVatMarketingCommission(this.formData.marketing_commission_vat.value)
            }
        },
        save() {
            let filtered = Object.entries({
                payment_status: this.formData.payment_status_id,
                rate_plan: this.formData.rate_plan,
                total: this.formData.total.channel,
                total_gross: this.formData.total_gross.channel,
                agency_commission_fixed: this.formData.agency_fixed_commission.channel,
                agency_commission: this.formData.agency_commission,
                channel_commission: this.formData.channel_commission,
                channel_commission_vat: this.formData.channel_vat,
                distribution_commission: this.formData.distribution_commission,
                distribution_commission_vat: this.formData.distribution_commission_vat,
                additional_commission: this.formData.additional_commission,
                additional_commission_vat: this.formData.additional_commission_vat,
                marketing_commission: this.formData.marketing_commission,
                marketing_commission_vat: this.formData.marketing_commission_vat,
                bank_commission: this.formData.bank_commission,
                platform_fee: this.formData.platform_fee_type,
            }).filter(([key, requestValue]) => {
                // skip request value objects that have type set to null
                return (!!requestValue && typeof requestValue === 'object' && requestValue.hasOwnProperty('type')) ? requestValue.type !== null : true
            })

            const request = Object.values(filtered).reduce((result, [key, requestValue]) => {
                if (!!requestValue && typeof requestValue === 'object' && requestValue.hasOwnProperty('type') && requestValue.hasOwnProperty('value')) {
                    result[key] = {
                        type: requestValue.type,
                        value: requestValue.value,
                    }
                } else {
                    result[key] = requestValue
                }
                return result
            }, {})

            this.saving = true
            updateAccommodationReservationFinance(this.reservation.id, request).then(response => {
                notifySuccess()
                this.$emit('update', response.data)
            }, error => {
                this.showErrorMessages(error, this.validation)
            }).finally(() => {
                this.saving = false
                if (request.total === 0) {
                    this.removeValueAlInput()
                }
            })
        },
        fetchFinanceValues(buttonLoading = true) {
            const promise = this.formData.rate_plan !== null ? fetchFinanceValues(this.reservation.id, {rate_plan: this.formData.rate_plan}) : fetchFinanceValues(this.reservation.id)
            if (buttonLoading) {
                this.fetchingFinancialValues = true
            }
            promise.then((response) => {
                this.setFinanceValues(response.data)
            }, (error) => {
                this.showErrorMessages(error)
            }).finally(() => {
                this.fetchingFinancialValues = false
            })
        },
        setFinanceValues(financeValues) {
            const channelCommissionType = financeValues.channel_commission.type !== null ? financeValues.channel_commission.type : PERC
            let channelCommissionValue = financeValues.channel_commission.value !== null ? Number(financeValues.channel_commission.value) : 0
            channelCommissionValue = Number(Math.round(parseFloat(channelCommissionValue + 'e' + DECIMAL_PLACES)) + 'e-' + DECIMAL_PLACES).toFixed(DECIMAL_PLACES)
            this.$set(this.formData.channel_commission, 'type', channelCommissionType)
            this.$set(this.formData.channel_commission, 'value', channelCommissionValue)

            const channelCommissionVatType = financeValues.channel_commission_vat.type !== null ? financeValues.channel_commission_vat.type : PERC
            let channelCommissionVatValue = financeValues.channel_commission_vat.value !== null ? Number(financeValues.channel_commission_vat.value) : 0
            channelCommissionVatValue = Number(Math.round(parseFloat(channelCommissionVatValue + 'e' + DECIMAL_PLACES)) + 'e-' + DECIMAL_PLACES).toFixed(DECIMAL_PLACES)
            this.$set(this.formData.channel_vat, 'type', channelCommissionVatType)
            this.$set(this.formData.channel_vat, 'value', channelCommissionVatValue)

            const agencyCommissionType = financeValues.agency_commission.type !== null ? financeValues.agency_commission.type : PERC
            let agencyCommissionValue = financeValues.agency_commission.value !== null ? Number(financeValues.agency_commission.value) : 0
            agencyCommissionValue = Number(Math.round(parseFloat(agencyCommissionValue + 'e' + DECIMAL_PLACES)) + 'e-' + DECIMAL_PLACES).toFixed(DECIMAL_PLACES)
            this.$set(this.formData.agency_commission, 'type', agencyCommissionType)
            this.$set(this.formData.agency_commission, 'value', agencyCommissionValue)

            let agencyCommissionFlatValue = financeValues.agency_commission_flat !== null ? Number(financeValues.agency_commission_flat) : 0
            agencyCommissionFlatValue = Number(Math.round(parseFloat(agencyCommissionFlatValue + 'e' + DECIMAL_PLACES)) + 'e-' + DECIMAL_PLACES).toFixed(DECIMAL_PLACES)
            this.$set(this.formData.agency_fixed_commission, 'channel', agencyCommissionFlatValue)

            const distributionCommissionType = financeValues.distribution_commission.type !== null ? financeValues.distribution_commission.type : PERC
            let distributionCommissionValue = financeValues.distribution_commission.value !== null ? Number(financeValues.distribution_commission.value) : 0
            distributionCommissionValue = Number(Math.round(parseFloat(distributionCommissionValue + 'e' + DECIMAL_PLACES)) + 'e-' + DECIMAL_PLACES).toFixed(DECIMAL_PLACES)
            this.$set(this.formData.distribution_commission, 'type', distributionCommissionType)
            this.$set(this.formData.distribution_commission, 'value', distributionCommissionValue)

            const distributionCommissionVatType = financeValues.distribution_commission_vat.type !== null ? financeValues.distribution_commission_vat.type : PERC
            let distributionCommissionVatValue = financeValues.distribution_commission_vat.value !== null ? Number(financeValues.distribution_commission_vat.value) : 0
            distributionCommissionVatValue = Number(Math.round(parseFloat(distributionCommissionVatValue + 'e' + DECIMAL_PLACES)) + 'e-' + DECIMAL_PLACES).toFixed(DECIMAL_PLACES)
            this.$set(this.formData.distribution_commission_vat, 'type', distributionCommissionVatType)
            this.$set(this.formData.distribution_commission_vat, 'value', distributionCommissionVatValue)

            const additionalCommissionType = financeValues.additional_commission.type !== null ? financeValues.additional_commission.type : PERC
            let additionalCommissionValue = financeValues.additional_commission.value !== null ? Number(financeValues.additional_commission.value) : 0
            additionalCommissionValue = Number(Math.round(parseFloat(additionalCommissionValue + 'e' + DECIMAL_PLACES)) + 'e-' + DECIMAL_PLACES).toFixed(DECIMAL_PLACES)
            this.$set(this.formData.additional_commission, 'type', additionalCommissionType)
            this.$set(this.formData.additional_commission, 'value', additionalCommissionValue)

            const additionalCommissionVatType = financeValues.additional_commission_vat.type !== null ? financeValues.additional_commission_vat.type : PERC
            let additionalCommissionVatValue = financeValues.additional_commission_vat.value !== null ? Number(financeValues.additional_commission_vat.value) : 0
            additionalCommissionVatValue = Number(Math.round(parseFloat(additionalCommissionVatValue + 'e' + DECIMAL_PLACES)) + 'e-' + DECIMAL_PLACES).toFixed(DECIMAL_PLACES)
            this.$set(this.formData.additional_commission_vat, 'type', additionalCommissionVatType)
            this.$set(this.formData.additional_commission_vat, 'value', additionalCommissionVatValue)

            const marketingCommissionType = financeValues.marketing_commission.type !== null ? financeValues.marketing_commission.type : PERC
            let marketingCommissionValue = financeValues.marketing_commission.value !== null ? Number(financeValues.marketing_commission.value) : 0
            marketingCommissionValue = Number(Math.round(parseFloat(marketingCommissionValue + 'e' + DECIMAL_PLACES)) + 'e-' + DECIMAL_PLACES).toFixed(DECIMAL_PLACES)
            this.$set(this.formData.marketing_commission, 'type', marketingCommissionType)
            this.$set(this.formData.marketing_commission, 'value', marketingCommissionValue)

            const marketingCommissionVatType = financeValues.marketing_commission_vat.type !== null ? financeValues.marketing_commission_vat.type : PERC
            let marketingCommissionVatValue = financeValues.marketing_commission_vat.value !== null ? Number(financeValues.marketing_commission_vat.value) : 0
            marketingCommissionVatValue = Number(Math.round(parseFloat(marketingCommissionVatValue + 'e' + DECIMAL_PLACES)) + 'e-' + DECIMAL_PLACES).toFixed(DECIMAL_PLACES)
            this.$set(this.formData.marketing_commission_vat, 'type', marketingCommissionVatType)
            this.$set(this.formData.marketing_commission_vat, 'value', marketingCommissionVatValue)

            const transactionCommissionType = financeValues.transaction_commission.type !== null ? financeValues.transaction_commission.type : PERC
            let transactionCommissionValue = financeValues.transaction_commission.value !== null ? Number(financeValues.transaction_commission.value) : 0
            transactionCommissionValue = Number(Math.round(parseFloat(transactionCommissionValue + 'e' + DECIMAL_PLACES)) + 'e-' + DECIMAL_PLACES).toFixed(DECIMAL_PLACES)
            this.$set(this.formData.bank_commission, 'type', transactionCommissionType)
            this.$set(this.formData.bank_commission, 'value', transactionCommissionValue)

            this.calculateChannelCommission(channelCommissionValue)
            this.calculateVatChannelCommission(channelCommissionVatValue)
            this.calculateAgencyCommission(agencyCommissionValue)
            this.calculateTransactionCommission(transactionCommissionValue)
            this.calculateDistributionCommission(distributionCommissionValue)
            this.calculateVatDistributionCommission(distributionCommissionVatValue)
            this.calculateAdditionalCommission(additionalCommissionValue)
            this.calculateVatAdditionalCommission(additionalCommissionVatValue)
            this.calculateMarketingCommission(marketingCommissionValue)
            this.calculateVatMarketingCommission(marketingCommissionVatValue)
        },
        ratePlanOptionAccessControlUpdated(parameters) {
            this.ratePlanOptionVisible = parameters.visible
        },
    },
    created() {
        this.access_control_general_context = {
            reservation: this.reservation.id,
        }

        this.formData = null
        this.formDataFormulasEnabled = false
        fetchAccommodationReservationFinance(this.reservation.id).then(response => {
            this.formData = cloneDeep(response.data)
            this.$set(this.formData, 'rate_plan', this.reservation.rate_plan.id)
            if (this.formData.hasOwnProperty('platform_fee_type')) {
                this.$set(this.formData.platform_fee_type, 'value', this.formData.platform_fee_type.value || "0.00")
            }
            this.$nextTick(() => {
                this.formDataFormulasEnabled = true
            })
        }, error => {
            this.showErrorMessages(error)
            this.$emit('close')
        })

        getStatusList(OBJECT_TYPE_ACCOMMODATION_RESERVATION_FINANCE).then(res => {
            this.paymentStatusOptions = res.data.filter(status => status.object_type.id === OBJECT_TYPE_ACCOMMODATION_RESERVATION_FINANCE)
        })

        const getRatePlanListRequest = {
            contingent_unit: this.reservation.unit.id,
            distribution: this.reservation.distribution.id,
            category_ids: RATE_PLAN_CATEGORY_DISTRIBUTION,
            perPage: 1000,
        }
        getRatePlanList(getRatePlanListRequest).then((response) => {
            this.ratePlanList = this.reservation.distribution.id !== null ? response.data.items : response.data.items.map((ratePlan) => ({
                    id: ratePlan.id,
                    name: ratePlan.distribution.id !== null ? ratePlan.name + ' (' + ratePlan.distribution.name + ')' : ratePlan.name
                })
            )
        })
    }
}
</script>

<style scoped>

</style>
